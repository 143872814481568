.contract-main {
    min-height: inherit;
    .contract-info {
        margin-top: 2.3rem;

        @include bp('lg-max') {
            margin-top: 1.5rem;
        }

        .main-info {
            color: $black2;
        }

        .training-info {
            margin-top: 1.4rem;

            ul {
                margin-top: 1.6rem;

                @include bp('lg-max') {
                    margin-top: 1rem;
                }

                li {
                    margin-bottom: 0.7rem;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    p {
                        color: $black2;
                    }
                }
            }
        }
    }

    .contract-from {
        margin-top: 2rem;
        width: 71rem;
        max-width: 100%;

        @include bp('sm-max') {
            margin-top: 1rem;
            width: 100%;
        }

        .field-with-label {
            .form-label {
                min-width: 18.6rem;

                @include bp('sm-max') {
                    min-width: 100%;
                }
            }

            .field-box {
                width: calc(100% - 19.6rem);

                @include bp('sm-max') {
                    width: 100%;
                }
            }
        }
    }

    .complete-training-info {
        // width: 62.6rem;
        margin-top: 2.4rem;
        max-width: 100%;

        @include bp('lg-max') {
            margin-top: 1.5rem;
        }

        .training-info-outer {
            margin-top: 0.7rem;
            @include flexbox();
            @include flex-wrap(wrap);

            .train-inf-check {
                margin-bottom: -0.6rem;
                width: 50%;

                @include bp('sm-max') {
                    margin-bottom: 0;
                    width: 100%;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .MuiFormControlLabel-root {
                    .MuiCheckbox-root {
                        @include bp('sm-max') {
                            padding: 0.4rem 0.9rem;
                        }
                    }

                    .MuiFormControlLabel-label {
                        color: $black2;

                        @include bp('sm-max') {
                            font-size: 1.4rem;
                        }
                    }
                }
            }
        }
    }

    .sign-contract-block {
        margin-top: 1.1rem;
        width: 82.8rem;
        max-width: 100%;

        @include bp('sm-max') {
            width: 100%;
            margin-top: 0;
        }

        .sign-info {
            color: $black2;
            margin-bottom: 1.5rem;

            @include bp('sm-max') {
                margin-bottom: 1rem;
            }
        }

        .sign-info-box {
            width: 73rem;
            max-width: 100%;
            @include flexbox();
            @include flex-wrap(wrap);
            margin: 0 -1rem;

            @include bp('sm-max') {
                width: 100%;
                margin: 0;
            }

            .sign-text-left {
                width: 62.4%;
                padding: 0 1rem 2rem;

                @include bp('sm-max') {
                    width: 100%;
                    padding: 0 0 1.5rem;
                }

                .field-with-label {
                    .form-label {
                        min-width: 14.1rem;

                        @include bp('sm-max') {
                            min-width: 100%;
                        }
                    }

                    .field-box {
                        width: calc(100% - 14.1rem);

                        @include bp('sm-max') {
                            width: 100%;
                        }
                    }
                }
            }

            .sign-text-right {
                width: 37.6%;
                padding: 0 1rem 2rem;

                @include bp('sm-max') {
                    width: 100%;
                    padding: 0 0 1rem;
                }

                .field-with-label {
                    .form-label {
                        min-width: auto;

                        @include bp('sm-max') {
                            min-width: 100%;
                        }
                    }

                    .field-box {
                        width: auto;

                        @include bp('sm-max') {
                            width: 100%;
                        }

                        .MuiStack-root {
                            padding: 0;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }

    .sign-track {
        width: 50rem;
        max-width: 100%;

        @include bp('sm-max') {
            width: 100%;
        }

        .field-with-label {
            .form-label {
                min-width: 22rem;

                @include bp('sm-max') {
                    min-width: 100%;
                }
            }

            .field-box {
                width: calc(100% - 23rem);

                @include bp('sm-max') {
                    width: 100%;
                }
            }
        }
    }
}

.edit-contract-page {
    &.page-footer-block {
        @include bp('xs-max') {
            @include flex-wrap(wrap);
            @include align-items(flex-start);
        }

        .MuiButton-contained {
            @include bp('xs-max') {
                width: 50%;
                margin-bottom: 1rem;
            }

            @include bp('xxxs-max') {
                width: 100%;
                margin-bottom: 0;
            }
        }
    }
}

.print-contract-page {
    &.page-footer-block {
        @include bp('xs-max') {
            @include flex-wrap(wrap);
            @include align-items(flex-start);
        }

        .MuiButton-contained {
            min-width: 9.3rem;
            @include bp('xs-max') {
                width: 50%;
                margin-bottom: 1rem;
                min-width: auto;
            }

            @include bp('xxxs-max') {
                width: 100%;
                margin-bottom: 0;
            }
        }
    }
}

.contract-pdf {
    max-height: 110.7rem;
    overflow: hidden auto;
}
