.new-upload-page {
    .page-footer-block {
        .MuiButton-root {
            width: 9rem;
            min-width: 11rem;

            @include bp('xs-max') {
                width: calc(50% - 0.5rem);
                margin: 0 -0.5rem 0 0;
            }
        }
    }

    .field-with-label {
        .field-box {
            width: calc(100% - 9.8rem);

            @include bp('sm-max') {
                width: 100% !important;
            }

            .MuiTextField-root {
                width: 71rem;
                max-width: 100%;

                @include bp('smd-max') {
                    width: 100%;
                }

                .MuiInputBase-input {
                    font-size: 1.4rem;
                    padding: 1.4rem 1.3rem;
                }
            }
        }

        &.small-date-box {
            .field-box {
                width: 71rem;
                max-width: 100%;

                @include bp('smd-max') {
                    width: 100%;
                }
            }
        }
    }
}

.text-form-box {
    margin-top: 2.2rem;
    width: 91.8rem;
    max-width: 100%;
}

.pdf-view {
    margin-top: 3rem;
    // max-height: 58.8rem;
    overflow: hidden auto;

    img {
        width: 100%;
    }
}

.education-page {
    .page-footer-block {
        @include bp('xs-max') {
            @include flex-wrap(wrap);
            @include align-items(flex-start);
        }

        .MuiButton-containedPrimary {
            width: 9rem;

            @include bp('xs-max') {
                width: 50%;
                margin-bottom: 1rem;
            }

            @include bp('xxxs-max') {
                width: 100%;
                margin-bottom: 0;
            }
        }

        .MuiButton-outlinedPrimary {
            @include bp('xxxs-max') {
                margin-top: 1rem;
            }
        }
    }

    .text-form-box {
        @include bp('lg-max') {
            width: 100%;
        }
    }

    .field-with-label {
        .field-box {
            width: 71rem;
            max-width: 100%;

            @include bp('lg-max') {
                width: 90rem;
            }

            @include bp('xs-max') {
                width: 100%;
            }
        }
    }
}

.public-education-page {
    padding: 2.4rem 0;

    .page-footer-block {
        .MuiButton-root {
            &.MuiButton-containedPrimary {
                padding: 1rem 1.4rem;

                @include bp('xs-max') {
                    width: 50%;
                }

                @include bp('xxxs-max') {
                    width: 100%;
                    margin-bottom: 1rem;
                }
            }

            &.MuiButton-outlinedPrimary {
                padding: 0.9rem 1.1rem;
            }
        }
    }

    .pdf-view {
        margin-top: 1.2rem;
    }
}
