.footer {
    padding: 2rem 1.5rem;
    background-color: $white;
    text-align: center;
    position: relative;
    z-index: 1;

    @include bp('smd-max') {
        position: static;
    }

    @include bp('sm-max') {
        padding: 1.2rem 1.5rem;
    }

    .copyright {
        line-height: 2.4rem;
        color: $black2;

        @include bp('sm-max') {
            line-height: 2rem;
        }
    }
}