.my-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.mt-5 {
    margin-top: 0.5rem;
}

.mt-10 {
    margin-top: 1rem !important;
}

.mt-15 {
    margin-top: 1.5rem;
}

.mt-20 {
    margin-top: 2rem !important;
}

.mt-30 {
    margin-top: 3rem;
}

.mb-5 {
    margin-bottom: 0.5rem;
}

.mb-10 {
    margin-bottom: 1rem;
}

.mb-15 {
    margin-bottom: 1.5rem;
}

.mb-20 {
    margin-bottom: 2rem !important;
}

.mb-30 {
    margin-bottom: 3rem !important;
}

.mx-0 {
    margin-left: 0;
    margin-right: 0;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-5 {
    margin-left: 0.5rem;
}

.ml-10 {
    margin-left: 1rem;
}

.ml-15 {
    margin-left: 1.5rem;
}

.ml-20 {
    margin-left: 2rem;
}

.mr-5 {
    margin-right: 0.5rem;
}

.mr-10 {
    margin-right: 1rem;
}

.mr-15 {
    margin-right: 1.5rem;
}

.mr-20 {
    margin-right: 2rem;
}

.p-0 {
    padding: 0 !important;
}

.p-10 {
    padding: 1rem !important;
}

.py-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.pt-5 {
    padding-top: 0.5rem;
}

.pt-10 {
    padding-top: 1rem;
}

.pt-15 {
    padding-top: 1.5rem;
}

.pt-20 {
    padding-top: 2rem;
}

.pb-5 {
    padding-bottom: 0.5rem;
}

.pb-10 {
    padding-bottom: 1rem;
}

.pb-15 {
    padding-bottom: 1.5rem;
}

.pb-20 {
    padding-bottom: 2rem;
}

.pb-30 {
    padding-bottom: 3rem;
}

.px-0 {
    padding-left: 0;
    padding-right: 0;
}

.pl-5 {
    padding-left: 0.5rem;
}

.pl-10 {
    padding-left: 1rem;
}

.pl-15 {
    padding-left: 1.5rem;
}

.pl-20 {
    padding-left: 2rem;
}

.pr-5 {
    padding-right: 0.5rem;
}

.pr-10 {
    padding-right: 1rem;
}

.pr-15 {
    padding-right: 1.5rem;
}

.pr-20 {
    padding-right: 2rem;
}

.d-flex {
    display: flex !important;
}

.flex-column {
    flex-direction: column !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

.max-150 {
    max-width: 15rem;
}

.max-200 {
    max-width: 20rem;
}

.max-280 {
    max-width: 28rem;
}

.max-300 {
    max-width: 30rem;
}

.max-350 {
    max-width: 35rem;
}

.overflow-hidden {
    overflow: hidden;
}
