::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}
::-webkit-scrollbar-thumb {
    border: 0 !important;
    background-color: #bcbcbc !important;
    border-radius: 3px !important;
}

body {
    overflow: hidden;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.loader-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-error-label {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    height: 4.8rem;

    @include bp('sm-max') {
        height: auto !important;
        justify-content: flex-start !important;
    }
}

// field-with-button css
.upload-button {
    @include bp('sm-max') {
        margin-top: 2.4rem;
    }
    @include bp('xs-max') {
        margin-top: 0;
        width: 100%;
    }
}

// pdf-viewer toolbar
.rpv-default-layout__main {
    .rpv-default-layout__body {
        padding-top: 5rem;

        @include bp('xsm-max') {
            padding-top: 7.5rem;
        }

        @include bp('xxxs-max') {
            padding-top: 10rem;
        }

        .rpv-default-layout__toolbar {
            height: auto;
            padding: 0;

            .toolBar {
                @include flexbox();
                @include flex-wrap(wrap);
                @include align-items(center);
                @include justify-content(space-between);
                padding: 0.7rem 1.5rem;
                width: 100%;

                .toolbar-left-action,
                .toolbar-right-action,
                .toolbar-page-action {
                    @include flexbox();
                    @include align-items(center);
                    @include justify-content(space-between);
                }

                .toolbar-right-action {
                    @include bp('xsm-max') {
                        @include justify-content(flex-start);
                        width: 100%;
                        padding-top: 0.7rem;
                    }
                }

                .toolbar-left-action {
                    .rpv-search__popover-input-counter {
                        width: 19.2rem;
                    }
                }

                .currentPage {
                    @include flexbox();
                    padding: 0 0.2rem;

                    .numberOfPages {
                        @include flexbox();
                        @include align-items(center);
                        @include justify-content(center);
                        color: $black;
                        font-size: 1.4rem;
                    }

                    .currentPageInput {
                        width: 4rem;
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
}

.rpv-core__inner-pages {
    max-height: 58.8rem;
}

.rpv-core__viewer {
    min-height: 7rem !important;
}

#rpv-core__popover-body-inner-zoom .rpv-core__menu-item:nth-child(2) {
    display: none !important;
}

//toaster
.Toastify__toast-body {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
}

.Toastify__toast-container {
    width: 40rem;
}

//table
.table-checkbox-width {
    width: 5.6rem;
}

.custom-track-vertical {
    right: 2px;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;

    .custom-thumb-vertical {
        cursor: pointer;
        border-radius: inherit;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 5;
    }
}

//Badge
.MuiButtonBase-root {
    .MuiBadge-root {
        position: absolute;
        top: 0;
        right: 0;

        .MuiBadge-badge {
            font-size: 1rem;
        }
    }
}

.datepicker-input {
    .MuiInputBase-root {
        input {
            text-transform: lowercase;
        }
    }
}

.MuiDayCalendar-weekContainer .MuiPickersDay-today {
    background-color: rgba(65, 130, 226, 0.08) !important;
    color: #262626 !important;
}

.MuiDayCalendar-weekContainer .Mui-selected {
    background-color: #4182e2 !important;
    color: #ffffff !important;
}

.MuiPickersSlideTransition-root {
    min-height: 260px !important;
}

.MuiDateCalendar-root {
    height: auto !important;
    max-height: none !important;
}

.page-footer-block {
    button {
        &:first-child {
            margin-left: 0 !important;

            @include bp('xs-max') {
                margin-left: -0.5rem !important;
            }

            @include bp('xxxs-max') {
                margin-left: 0 !important;
            }
        }
    }
}

.result-box-full {
    .pdf-view {
        margin-top: 0;
    }
}

.attestation-preview-values {
    font-size: 1.6rem !important;
    margin-left: 0.5rem !important;
}

.multiple-contained-buttons {
    .page-footer-block {
        @include bp('xs-max') {
            @include flex-wrap(wrap);
            @include align-items(flex-start);
        }

        .MuiButton-containedPrimary {
            @include bp('xs-max') {
                width: 50%;
                margin-bottom: 1rem;
            }

            @include bp('xxxs-max') {
                width: 100%;
                margin-bottom: 0;
            }
        }

        .MuiButton-outlinedPrimary {
            @include bp('xxxs-max') {
                margin-top: 1rem;
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

.MuiTooltip-popper {
    .MuiTooltip-tooltip {
        line-height: 1.8rem;
        font-size: 1.1rem;
    }
}

input[type='checkbox'][disabled] + img {
    filter: opacity(0.6);
}

.MuiTableSortLabel-icon {
    fill: rgb(115, 115, 115) !important;
}

// Attestation preview
.preview-dialog {
    .MuiDialog-paper {
        width: inherit;
        max-width: 120rem;
    }

    .dialog-header {
        position: sticky;
        top: 0;
    }

    .MuiDialogContent-root {
        padding: 2rem 5rem;
        max-height: 80vh;

        .attestation-preview-header {
            display: flex;
            align-items: center;
            justify-content: space-around;

            .logo-img {
                min-height: 14rem;
            }

            .preview-header-details {
                max-width: 70rem;
            }
        }

        .contract-from {
            .field-with-label {
                .form-label {
                    justify-content: flex-start;
                    min-width: 20rem;
                }
                margin-bottom: 0;
            }
        }

        .sign-contract-block {
            .sign-info-box {
                flex-direction: column;

                .sign-text-left {
                    width: auto;
                    padding: 0 1rem 0;

                    .field-with-label {
                        flex-wrap: nowrap;

                        @include bp('xs-max') {
                            flex-wrap: wrap;
                        }

                        .form-label {
                            justify-content: flex-start;
                            min-width: 20rem;
                        }
                    }
                }

                .sign-text-right {
                    width: auto;
                    padding: 0 1rem 0;

                    .field-with-label {
                        flex-wrap: nowrap;

                        @include bp('xs-max') {
                            flex-wrap: wrap;
                        }

                        .form-label {
                            justify-content: flex-start;
                            min-width: 20rem;
                        }
                    }
                }

                .sign-track {
                    width: auto;
                    padding: 0 1rem 0;
                }
            }
        }
    }
}

.header_top {
    min-height: 4.2rem;
}

/* Jodit 
****************************************************************************/
.jodit-toolbar__box:not(:empty) {
    border-radius: 5px 5px 0 0;
}
.jodit-wysiwyg.full-page-editor {
    min-height: calc(100vh - 266px) !important;
}
.jodit_sticky > .jodit-toolbar__box {
    z-index: 2;
}

/* Reset-css for JoitEditor
****************************************************************************/
.reset-css strong,
.reset-css b {
    font-weight: 700 !important;
}
.reset-css i {
    font-style: italic !important;
}
.reset-css ul {
    list-style-position: inside !important;
    padding-inline-start: 40px;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
}
.reset-css ol {
    list-style-position: inside !important;
    padding-inline-start: 40px;
    list-style: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
}
.reset-css code {
    font-family: 'monospace', sans-serif !important;
    overflow-wrap: break-word;
    background: rgb(241, 241, 241);
    border-radius: 3px;
    padding: 1px 3px;
    letter-spacing: 1.2px;
}
.reset-css sup {
    vertical-align: super;
    font-size: smaller;
}
.reset-css sub {
    vertical-align: sub;
    font-size: smaller;
}
.reset-css a {
    color: #3d73dd;
}
.reset-css a:hover,
.reset-css a:focus {
    opacity: 0.7;
}
.reset-css img {
    display: block;
}
.reset-css h1 {
    font-size: 1.8rem;
    line-height: 1.2;
    font-weight: 400;
    letter-spacing: 0;
}
.reset-css h2 {
    font-size: 1.3rem;
    line-height: 1.2;
    font-weight: 400;
    letter-spacing: 0;
}
.reset-css h3 {
    font-size: 1.7rem;
    line-height: 1.2;
    font-weight: 400;
    letter-spacing: 0;
}
.reset-css h4 {
    font-size: 1.6rem;
    line-height: 1.2;
    font-weight: 400;
    letter-spacing: 0;
}
.reset-css h5 {
    font-size: 1.3333rem;
    line-height: 1.2;
    font-weight: 400;
    letter-spacing: 0;
}
.reset-css h6 {
    font-size: 1.0666rem;
    line-height: 1.2;
    font-weight: 400;
    letter-spacing: 0;
}
.reset-css p {
    padding-bottom: 10px;
    min-height: 15px;
    text-align: justify;
}
.reset-css em {
    font-style: italic;
}
.jodit-wysiwyg .main-table tr td {
    border: 0 !important;
    padding: 0;
}
.reset-css .jodit-container {
    background-color: white;
}
.reset-css html {
    height: 100% !important;
    min-height: auto !important;
}
.reset-css body {
    height: 100% !important;
    min-height: auto !important;
}
.jodit.jodit-dialog .jodit-dialog__content,
.jodit.jodit-dialog .jodit-dialog_prompt,
.jodit.jodit-dialog .jodit-dialog__footer,
.jodit.jodit-dialog .jodit-dialog__header {
    background-color: white;
}

.password-info {
    margin-bottom: 1rem;
    /* color: #f30000; */
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
}

.hero-text {
    @include bp('md-max') {
        text-align: center;
        p {
            text-align: left;
        }
    }
    .float-img {
        float: right;
        padding: 20px;
        @include bp('md-max') {
            float: none;
        }
    }
    .MuiTypography-h1 span {
        color: #4182e2;
    }
    p {
        text-align: justify;
    }
}
.contact-block {
    margin-top: 20px;
}
.admin-main-box .admin-menu .menu-box ul {
    align-items: center;
}
.home-page.contact-page .page-content .h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
}
