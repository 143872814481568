.dashboard-page {
    padding: 2.3rem 0 3rem;

    @include bp('xs-max') {
        padding: 1.5rem 0;
    }
}

.page-info-block {
    @include flexbox();
    @include flex-wrap(wrap);
    margin-top: 2.1rem;

    @include bp('sm-max') {
        margin-top: 1.5rem;
    }

    .page-info-left {
        width: calc(100% - 18.4rem);
        margin-right: 2.4rem;
        max-width: 100%;
        @include flexbox();

        @include bp('smd-max') {
            width: 100%;
            margin-right: 0;
            margin-bottom: 1.5rem;
        }

        @include bp('sm-max') {
            @include flex-wrap(wrap);
        }

        .topic-box {
            @include flexbox();
            @include align-items(center);
            width: 23.4rem;
            max-width: 100%;

            @include bp('xlg-max') {
                width: 20rem;
            }

            @include bp('sm-max') {
                width: 100%;
                @include flex-wrap(wrap);
            }

            .form-label {
                margin-right: 1rem;

                @include bp('xlg-max') {
                    margin-right: 0.5rem;
                }

                @include bp('sm-max') {
                    margin-bottom: 0.5rem;
                    margin-right: 0;
                }
            }

            .MuiTextField-root {
                width: 17.8rem;
                max-width: 100%;

                @include bp('sm-max') {
                    width: 100%;
                }

                .MuiOutlinedInput-input {
                    padding: 0.85rem 1.2rem;
                }
            }
        }

        .date-box-outer {
            @include flexbox();
            @include align-items(center);
            width: calc(100% - 25.4rem);
            max-width: 100%;
            margin-left: 2rem;

            @include bp('xlg-max') {
                width: calc(100% - 20rem);
                margin-left: 1.5rem;
            }

            @include bp('sm-max') {
                width: 100%;
                margin: 1.5rem -0.5rem 0;
            }

            @include bp('xs-max') {
                margin: 1.5rem 0 0;
                @include flex-wrap(wrap);
            }

            .date-box {
                @include flexbox();
                @include align-items(center);

                @include bp('sm-max') {
                    @include flex-wrap(wrap);
                    width: 50%;
                    padding: 0 0.5rem;
                }

                @include bp('xs-max') {
                    width: 100%;
                    padding: 0;
                    margin: 0 0 1rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .form-label {
                    margin-right: 1rem;

                    @include bp('xlg-max') {
                        margin-right: 0.5rem;
                    }

                    @include bp('sm-max') {
                        margin: 0;
                    }
                }

                .MuiStack-root {
                    padding-top: 0;
                    width: 16.1rem;
                    max-width: 100%;
                    overflow: hidden;

                    @include bp('xlg-max') {
                        width: 15rem;
                    }

                    @include bp('sm-max') {
                        width: 100%;
                        padding-top: 0.5rem;
                    }

                    > .MuiTextField-root {
                        min-width: auto;
                    }
                }

                .MuiOutlinedInput-input {
                    padding: 1rem 1.6rem;
                    font-size: 1.4rem;
                    line-height: 2rem;

                    @include placeholder {
                        text-transform: lowercase !important;
                    }
                }

                .MuiInputAdornment-root {
                    margin-left: -3.3rem;
                }
            }

            .divider {
                @include inline-block();
                padding: 0 1.3rem;

                @include bp('xlg-max') {
                    padding: 0 0.5rem;
                }

                @include bp('sm-max') {
                    display: none;
                }
            }
        }
    }

    .page-info-right {
        width: 16rem;
        @include flexbox();
        @include align-items(center);

        @include bp('smd-max') {
            width: 100%;
        }

        .MuiButton-root {
            font-size: 1.4rem;

            &.MuiButton-containedPrimary {
                padding: 0.6rem 1.1rem;
            }

            &.MuiButton-outlinedPrimary {
                padding: 0.5rem 1.1rem;
                margin-left: 1.2rem;
            }
        }
    }

    &.role-block {
        .page-info-left {
            width: auto;
            margin-right: 1.6rem;
            display: block;
            margin-bottom: 0;

            @include bp('xs-max') {
                margin-right: 0;
                width: 100%;
                margin-bottom: 1rem;
            }

            .topic-box {
                width: auto;

                @include bp('xs-max') {
                    width: 100%;
                }

                .MuiTextField-root {
                    width: 21.2rem;

                    @include bp('xs-max') {
                        width: 100%;
                    }
                }

                .form-label {
                    @include bp('sm-max') {
                        margin-bottom: 0;
                        margin-right: 1rem;
                    }

                    @include bp('xs-max') {
                        margin-right: 0;
                        margin-bottom: 1rem;
                        display: block;
                    }
                }
            }
        }

        .page-info-right {
            width: auto;

            @include bp('xs-max') {
                width: 100%;
            }

            .MuiButton-containedPrimary {
                padding: 0.8rem 1.1rem;
            }
        }
    }

    &.user-info-block {
        .page-info-left {
            width: auto;

            @include bp('smd-max') {
                margin-bottom: 0;
                margin-right: 1rem;
            }

            @include bp('sm-max') {
                margin-right: 0;
                margin-bottom: 1rem;
                width: 100%;
            }

            .topic-box {
                width: 29.7rem;
                margin-right: 2rem;

                @include bp('smd-max') {
                    width: 26rem;
                    margin-right: 1rem;
                }

                @include bp('sm-max') {
                    width: calc(50% - 1rem);
                    margin-right: 1rem;
                }

                @include bp('xs-max') {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 1rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }

                .MuiTextField-root {
                    width: 21.2rem;

                    @include bp('smd-max') {
                        width: 18rem;
                    }

                    @include bp('sm-max') {
                        width: 100%;
                    }
                }
            }
        }

        .page-info-right {
            @include bp('smd-max') {
                width: auto;
            }

            @include bp('sm-max') {
                width: 100%;
            }
        }
    }
}

.page-footer-block {
    background-color: $white;
    box-shadow: 0 0 5rem 2rem rgba(65, 130, 226, 0.08);
    padding: 2rem;
    @include border-radius(1rem);
    width: calc(100% - 2.4rem);
    margin: 2.4rem 1.2rem 0;

    @include bp('lg-max') {
        width: 100%;
        margin: 2.4rem 0 0;
    }

    @include bp('smd-max') {
        padding: 1.5rem;
        margin: 1.5rem 0 0;
    }

    @include bp('xs-max') {
        @include flexbox();
        @include justify-content(space-between);
    }

    @include bp('xxxs-max') {
        @include flex-wrap(wrap);
    }

    .MuiButton-root {
        &.single-button {
            width: auto;
        }

        @include bp('xs-max') {
            width: 50%;
            margin: 0 -0.5rem;
        }

        @include bp('xxxs-max') {
            width: 100%;
            margin: 0;
        }

        &.MuiButton-containedPrimary {
            padding: 1rem 1.15rem;

            margin-left: 1.6rem;

            @include bp('xs-max') {
                margin-left: -0.5rem;
            }

            @include bp('xxxs-max') {
                margin-top: 1rem;
                margin-left: 0;
            }
        }

        &.MuiButton-outlinedPrimary {
            padding: 0.9rem 1.2rem;
            margin-left: 1.6rem;

            @include bp('xs-max') {
                margin-left: -0.5rem;
            }

            @include bp('xxxs-max') {
                margin-top: 1rem;
                margin-left: 0;
            }
        }

        &.MuiButton-sizeMedium {
            min-width: 8.4rem;
        }

        &.MuiButton-sizeSmall {
            min-width: 7rem;
        }
    }
}
