//Break points
@mixin bp($point) {

	$xxxs-max: "(max-width: 359px)";
	$xxs-max: "(max-width: 374px)";
	$xsm-max: "(max-width: 413px)";
	$xs-max: "(max-width: 575px)";
	$sm-max: "(max-width: 767px)";
	$smd-max: "(max-width: 991px)";
	$md-max: "(max-width: 1024px)";
	$lg-max: "(max-width: 1199px)";
	$xlg-max: "(max-width: 1365px)";

	@if $point==xxxs-max {
		@media #{$xxxs-max} {
			@content;
		}
	}

	@if $point==xxs-max {
		@media #{$xxs-max} {
			@content;
		}
	}

	@if $point==xsm-max {
		@media #{$xsm-max} {
			@content;
		}
	}

	@else if $point==xs-max {
		@media #{$xs-max} {
			@content;
		}
	}

	@else if $point==sm-max {
		@media #{$sm-max} {
			@content;
		}
	}

	@else if $point==smd-max {
		@media #{$smd-max} {
			@content;
		}
	}

	@else if $point==md-max {
		@media #{$md-max} {
			@content;
		}
	}

	@else if $point==lg-max {
		@media #{$lg-max} {
			@content;
		}
	}

	@else if $point==xlg-max {
		@media #{$xlg-max} {
			@content;
		}
	}

}