html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-style: inherit;
    @include vertical-align(baseline);
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

::-moz-selection {
    color: $white;
    background: $bluePrimary;
}

::selection {
    color: $white;
    background: $bluePrimary;
}

/* General 
--------------------------------------------------------------------------------------------------------*/
* {
    outline: none !important;
    box-sizing: border-box;

    &:after,
    &:before {
        box-sizing: border-box;
    }
}

html,
body {
    min-height: 100vh;
}

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    line-height: 2.8rem;
    min-width: 32rem;
    background: $skyPrimaryLight;
    color: $textPrimary;
    font-family: $regularFont;
    -webkit-font-smoothing: subpixel-antialiased;
    -ms-overflow-style: scrollbar;
    -webkit-text-size-adjust: none;

    @include bp('sm-max') {
        font-size: 1.4rem;
        line-height: 2.2rem;
    }
}

ul,
ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

*:focus {
    outline: none !important;
}

a {
    display: inline-block;
    @include transition(all 0.5s);
    text-decoration: none;
    color: $bluePrimary;

    &:hover {
        color: $bluePrimaryDark;
        text-decoration: none;
    }
}

.MuiContainer-root {
    max-width: 131.8rem !important;
    padding: 0 1.5rem !important;
}

img {
    max-width: 100%;
}

::-ms-clear {
    display: none;
}

input[type='text']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type='text']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

input {
    @include placeholder {
        font-size: 1.4rem !important;
        line-height: 2rem !important;
        opacity: 1 !important;
        color: $textPrimary !important;
    }
}

.wrapper {
    padding-top: 20.3rem;

    &.secondary-wrapper {
        padding-top: 16.4rem;
        min-height: calc(100vh - 6.4rem);

        @include bp('xs-max') {
            padding-top: 15.4rem;
        }

        .main-content {
            min-height: auto;
        }

        .home-page {
            // .home-section {
            //     height: calc(100vh - 30.8rem);

            //     @include bp('smd-max') {
            //         height: auto;
            //     }
            // }

            &.about-page {
                .home-section {
                    height: auto;
                }
            }
        }
    }

    @include bp('md-max') {
        padding-top: 13.8rem;
    }
}

.main-content {
    min-height: calc(100vh - 26.8rem);

    @include bp('md-max') {
        min-height: calc(100vh - 20.2rem);
    }

    @include bp('sm-max') {
        min-height: calc(100vh - 18.2rem);
    }
}

em {
    @include inline-block();
    font-style: normal;
}

.os-scrollbar {
    &.os-theme-dark {
        width: 1.2rem;

        .os-scrollbar-track {
            background: rgba($black, 0.04);

            .os-scrollbar-handle {
                background: rgba($silver, 0.8);
            }
        }
    }
}

.form-label {
    font-size: 1.4rem;
    line-height: 2rem;
    cursor: text;
}

.page-outer {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    margin: 0 -3.7rem;

    @include bp('md-max') {
        margin: 0;
        @include flex-wrap(wrap);
        @include justify-content(center);
    }

    &_left {
        width: 50%;
        padding: 0 3.7rem;

        @include bp('smd-max') {
            width: 100%;
            padding: 0 0 5rem;
        }

        .page-content {
            @include bp('smd-max') {
                max-width: 100%;
                text-align: center;
                width: 50rem;
                margin: 0 auto;
            }

            .MuiTypography-h1 {
                margin-bottom: 1rem;

                span {
                    color: $bluePrimary;
                }
            }

            .MuiButton-containedPrimary {
                .phone {
                    line-height: 0;
                    max-width: 2rem;
                }
            }
        }
    }

    &_right {
        width: 50%;
        padding: 0 3.7rem;

        @include bp('smd-max') {
            width: 100%;
            padding: 0;
        }

        .right-bg {
            text-align: center;
            position: relative;
            max-width: 50.4rem;
            margin: 2.2rem auto 0;

            @include bp('lg-max') {
                margin-top: 0;
            }

            @include bp('md-max') {
                max-width: 35rem;
            }

            @include bp('sm-max') {
                max-width: 30rem;
            }

            @include bp('xs-max') {
                max-width: 25rem;
            }

            &:before {
                content: '';
                position: absolute;
                left: 4.2rem;
                top: -2rem;
                border: 0.2rem solid $bluePrimary;
                border-radius: 0 8rem 0 8rem;
                right: 0;
                bottom: 3rem;

                @include bp('lg-max') {
                    right: -1rem;
                }

                @include bp('sm-max') {
                    left: 1.2rem;
                    top: -1rem;
                    right: -1rem;
                    bottom: 2rem;
                }
            }

            img {
                box-shadow: 0 0 5rem 2rem rgba(65, 130, 226, 0.08);
                border-radius: 0 8rem 0 8rem;
                position: relative;
                z-index: 1;
                max-width: calc(100% - 1rem);
            }
        }

        .page-bg {
            @include bp('smd-max') {
                text-align: center;
            }

            &.bg-bottom {
                text-align: center;
                position: relative;
                // position: absolute;
                // bottom: 0;
                // right: 9.8rem;
                line-height: 0;
                z-index: 1;

                // @include bp('smd-max') {
                //     position: static;
                // }

                img {
                    @include bp('lg-max') {
                        max-width: 35rem;
                    }

                    @include bp('smd-max') {
                        max-width: 32rem;
                    }

                    @include bp('xs-max') {
                        max-width: 26rem;
                    }
                }

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    border-radius: 6rem 6rem 0 0;
                    z-index: -1;
                }

                &:before {
                    // bottom: -6.2rem;
                    // right: -9.9rem;
                    bottom: 0;
                    right: 0;
                    width: 53.4rem;
                    height: 35.1rem;
                    border: 0.2rem solid $bluePrimary;

                    @include bp('md-max') {
                        right: -3rem;
                    }

                    @include bp('lg-max') {
                        // right: -3.5rem;
                        width: 40rem;
                    }

                    @include bp('smd-max') {
                        // display: none;
                        right: auto;
                        left: 50%;
                        height: 32rem;
                        transform: translateX(-50%);
                    }

                    @include bp('xs-max') {
                        width: 32rem;
                        height: 26rem;
                    }

                    @include bp('xxxs-max') {
                        width: 30rem;
                    }
                }

                &:after {
                    // bottom: -5.7rem;
                    // right: -3.2rem;
                    bottom: 0;
                    right: 6rem;
                    width: 51.9rem;
                    height: 29.3rem;
                    background-color: $bluePrimary;

                    @include bp('md-max') {
                        right: 1rem;
                    }

                    @include bp('lg-max') {
                        // right: -1rem;
                        // bottom: -2.7rem;
                        width: 40rem;
                    }

                    @include bp('smd-max') {
                        display: none;
                        // right: auto;
                        // left: 50%;
                        // transform: translateX(-60%);
                    }

                    // @include bp('xs-max') {
                    //     width: 32rem;
                    //     height: 26rem;
                    // }

                    // @include bp('xsm-max') {
                    //     transform: translateX(-50%);
                    // }

                    // @include bp('xxxs-max') {
                    //     width: 30rem;
                    // }
                }
            }
        }
    }
}

p {
    &.font-big {
        position: relative;
        font-size: 2rem;
        line-height: 3.4rem;
        // margin-bottom: 2rem;
        z-index: 1;
        padding-bottom: 3rem;

        @include bp('md-max') {
            font-size: 1.6rem;
            line-height: 2.5rem;
            // margin-bottom: 1.7rem;
        }

        @include bp('sm-max') {
            padding-bottom: 2rem;
        }
    }
}

// Contact us CSS
.contact-block {
    &-icon {
        width: 3.6rem;
        height: 3.6rem;
        background-color: $bluePrimary;
        margin: 0 auto;
        @include border-radius(50%);
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);

        img {
            max-width: 1.7rem;
        }
    }

    &-content {
        text-align: center;
        margin-top: 1rem;

        span {
            display: block;
            font-size: 1.4rem;
            color: $grey;
        }

        a {
            font-size: 1.8rem;
            font-weight: 500;
            color: $black2;
            display: block;

            @include bp('lg-max') {
                font-size: 1.6rem;
                word-break: break-word;
            }

            &:hover {
                color: $bluePrimaryDark;
            }
        }
    }
}

// Career Page CSS
.career-info {
    @include flexbox();
    @include flex-wrap(wrap);
    margin: 0 -1rem -0.2rem;

    @include bp('xs-max') {
        margin: 0;
    }

    &_list {
        padding: 0 1rem;

        @include bp('xs-max') {
            padding: 0;
        }

        &.left {
            width: 56.5%;

            @include bp('xs-max') {
                width: 100%;
                padding: 0 0 1.5rem;
            }
        }

        &.right {
            width: 43.5%;

            @include bp('xs-max') {
                width: 100%;
            }
        }

        li {
            font-size: 1.8rem;
            padding-left: 3rem;
            position: relative;
            margin-bottom: 1.8rem;

            @include bp('xlg-max') {
                font-size: 1.6rem;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:before {
                content: '';
                position: absolute;
                width: 2.4rem;
                height: 2.4rem;
                left: 0;
                top: 0.3rem;
                background-image: url('../../images/ic-check-circle.svg');
                background-repeat: no-repeat;
                background-size: 2.4rem;
            }
        }
    }
}

.contact-info {
    @include flexbox();
    @include flex-wrap(wrap);
    margin: 3.2rem -1.6rem 2rem;

    @include bp('smd-max') {
        margin: 3.2rem -1rem 2rem;
    }

    @include bp('xs-max') {
        margin: 2.5rem 0;
    }

    .contact-info-block {
        padding: 0 1.6rem;

        @include bp('smd-max') {
            padding: 0 1rem;
        }

        &.left {
            width: 39.2%;

            @include bp('xlg-max') {
                width: 50%;
            }

            @include bp('xs-max') {
                width: 100%;
                padding: 0 0 1.5rem;
            }
        }

        &.right {
            width: 60.2%;

            @include bp('xlg-max') {
                width: 50%;
            }

            @include bp('xs-max') {
                width: 100%;
                padding: 0;
            }
        }
    }

    &_outer {
        @include flexbox();
        @include align-items(center);

        &_icon {
            width: 4rem;
            height: 4rem;
            background-color: $bluePrimary;
            margin-right: 1.2rem;
            @include border-radius(50%);
            @include flexbox();
            @include justify-content(center);
            @include align-items(center);

            img {
                max-width: 2rem;
            }
        }

        &_content {
            width: calc(100% - 5.2rem);

            span {
                display: block;
                font-size: 1.4rem;
                line-height: 2.1rem;
            }

            a {
                font-weight: 500;
                color: $black2;

                &:hover {
                    color: $bluePrimaryDark;
                }
            }
        }
    }
}

.wrapper-bg {
    position: fixed;
    top: 13.8rem;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgba($black, 0.4);
    opacity: 0;
    visibility: hidden;
    @include transition(all 0.5s);

    @include bp('xs-max') {
        top: 13.4rem;
    }
}

@media screen and (min-width: 1025px) {
    .wrapper-bg {
        display: none;
    }
}

.menu-open {
    overflow: hidden;

    .wrapper-bg {
        opacity: 1;
        visibility: visible;
    }

    .header_bottom {
        .user-info {
            .hamburger-menu {
                span {
                    &:first-child {
                        transform: rotate(45deg);
                        top: 1rem;
                        width: 100%;
                    }

                    &:nth-child(2) {
                        opacity: 0;
                    }

                    &:last-child {
                        transform: rotate(-45deg);
                        top: 1rem;
                        width: 100%;
                    }
                }
            }
        }

        nav {
            left: 0;
        }
    }
}

.required {
    color: $red;
}

.MuiOutlinedInput-root {
    .MuiSelect-icon {
        background-image: url('../../images/ic-select-arrow.svg');
        background-repeat: no-repeat;
        background-size: 1.2rem;
        width: 1.2rem;
        height: 1.2rem;
        background-position: center;
        @include transition(all 0.5s);
        right: 1.6rem;

        path {
            display: none;
        }
    }
}

// Login Modal
.modal-small {
    .MuiDialog-paper {
        width: 52rem;
        max-width: 100%;
        @include border-radius(1rem);

        @include bp('sm-max') {
            width: 45rem;
            margin: 3.2rem 1.5rem;
        }

        .MuiDialogTitle-root {
            padding: 4.5rem 1.5rem 0;
            text-align: center;

            @include bp('sm-max') {
                padding: 2.5rem 1.5rem 0;
            }

            .MuiTypography-h3 {
                margin-bottom: 0.5rem;
            }

            span {
                color: $grey;
                line-height: 2.4rem;
                font-size: 1.6rem;
                font-weight: 400;
                letter-spacing: 0;
                font-family: 'Poppins', sans-serif;

                @include bp('sm-max') {
                    font-size: 1.3rem;
                    line-height: 1.8rem;
                }
            }
        }

        .MuiDialogContent-root {
            padding: 2.8rem 7rem 3.1rem;

            @include bp('sm-max') {
                padding: 2.5rem 1.5rem;
            }

            .checkbox-block {
                margin: 1.1rem 0;
            }
        }
    }
}

// Delete Modal CSS
.confirmation-dialog {
    .MuiDialog-paper {
        width: 36rem;
        max-width: 100%;
        @include border-radius(1.2rem);

        @include bp('sm-max') {
            @include border-radius(0.6rem);
        }
    }

    &.cancel-dialog {
        .MuiDialogContent-root {
            padding: 3rem 2rem;

            @include bp('sm-max') {
                padding: 2rem 1.5rem;
            }
        }
    }

    .dialog-header {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        background-color: $bluePrimary;
        padding: 1rem 1.5rem 1rem 2rem;

        @include bp('sm-max') {
            padding: 0.5rem 0.5rem 0.5rem 1rem;
        }

        .MuiTypography-h3 {
            color: $white;
        }
    }

    .MuiDialogContent-root {
        padding: 4rem 2rem;

        @include bp('sm-max') {
            padding: 2rem 1.5rem;
        }

        .confirm-message {
            line-height: 2.4rem;
            color: $black2;

            @include bp('sm-max') {
                line-height: 2rem;
                text-align: left;
            }
        }
    }

    .dialog-footer {
        padding: 0 2rem 2rem;
        text-align: right;

        @include bp('sm-max') {
            padding: 0 1.5rem 1.5rem;
        }

        .MuiButton-root {
            margin-right: 1.2rem;
            font-size: 1.4rem;
            padding: 0.6rem 1rem;
            width: 7.1rem;

            &:last-child {
                margin-right: 0;
            }

            &.MuiButton-outlinedPrimary {
                padding: 0.5rem 1rem;
            }
        }
    }
}

// Submit Modal CSS
.submit-dialog {
    .MuiDialog-paper {
        width: 50rem;
        max-width: 100%;
        @include border-radius(1.2rem);

        @include bp('sm-max') {
            @include border-radius(0.6rem);
        }
    }

    .dialog-header {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        background-color: $bluePrimary;
        padding: 1rem 1.5rem 1rem 2rem;

        @include bp('sm-max') {
            padding: 0.5rem 0.5rem 0.5rem 1rem;
        }

        .MuiTypography-h3 {
            color: $white;
        }
    }

    .MuiDialogContent-root {
        padding: 2rem;

        @include bp('sm-max') {
            padding: 1.5rem;
        }

        .confirm-message {
            line-height: 2.4rem;
            color: $black2;
            margin-bottom: 1.2rem;

            &:last-child {
                margin-bottom: 0;
            }

            @include bp('sm-max') {
                line-height: 2rem;
            }
        }
    }

    .dialog-footer {
        padding: 0 2rem 2rem;
        text-align: right;

        @include bp('sm-max') {
            padding: 0 1.5rem 1.5rem;
        }

        .MuiButton-root {
            margin-right: 1.2rem;
            font-size: 1.4rem;
            padding: 0.6rem 1.2rem;
            width: auto;
            text-transform: none;

            @include bp('xsm-max') {
                width: 100%;
                margin-right: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &.MuiButton-outlinedPrimary {
                padding: 0.5rem 1.2rem;

                @include bp('xsm-max') {
                    margin-top: 1rem;
                }
            }
        }
    }
}

.form-group {
    margin-bottom: 2rem;

    &.mb-0 {
        margin-bottom: 0;
    }
}

.user-menu {
    margin-left: 2.4rem;
}

.user-menu-outer {
    .MuiMenu-paper {
        position: relative;
        overflow: visible;
        margin-top: 1.5rem;

        &:before {
            content: '';
            position: absolute;
            right: 1.7rem;
            top: 0;
            width: 1rem;
            height: 1rem;
            transform: translateY(-50%) rotate(225deg);
            background-color: $white;
            box-shadow: 0.2rem 0.2rem 0.6rem rgba(0, 0, 0, 0.06);
        }
    }
    .MuiMenuItem-root {
        min-height: auto !important;
    }
}

.user-menu-info {
    padding: 0 0 1.1rem;
    border-bottom: 0.1rem solid $greyBorder;
    margin-bottom: 0.9rem;

    span {
        text-transform: capitalize;
        font-size: 1.4rem;
        line-height: 2rem;
        color: $grey;
    }

    p {
        text-transform: capitalize;
        font-weight: 500;
        line-height: 1.8rem;
        color: $black2;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.tab-block {
    padding: 2rem 3.2rem;
    background-color: $white;
    margin-bottom: 2.4rem;
    @include border-radius(1rem);
    box-shadow: 0 0 5rem 2rem rgba(65, 130, 226, 0.08);

    .MuiTabScrollButton-root {
        display: none;

        &.Mui-disabled {
            opacity: 0.5;
        }

        @include bp('smd-max') {
            @include inline-flex();
        }
    }

    @include bp('smd-max') {
        padding: 1rem 1.5rem;
        margin-bottom: 1.5rem;
    }

    @include bp('xs-max') {
        padding: 1rem;
    }

    .MuiTabs-root {
        min-height: 3.2rem;
    }
}

.admin-main-box {
    @include flexbox();
    @include flex-wrap(wrap);
    margin: 0 -1.2rem;

    @include bp('lg-max') {
        margin: 0;
    }

    .admin-menu {
        width: 32.9rem;
        padding: 0 1.2rem;

        @include bp('xlg-max') {
            width: 30rem;
        }

        @include bp('lg-max') {
            width: 100%;
            padding: 0;
            border-bottom: 0.1rem solid $greyBorder;
        }

        .menu-box {
            background-color: $white;
            padding: 1.2rem 1.6rem;
            box-shadow: 0 0 5rem 2rem rgba(65, 130, 226, 0.08);
            @include border-radius(1rem);
            height: 100%;

            @include bp('xlg-max') {
                padding: 1.2rem 1rem;
            }

            @include bp('lg-max') {
                padding: 0 1rem;
                overflow: hidden;
                // height: 5rem;
                box-shadow: none;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            ul {
                @include bp('lg-max') {
                    @include flexbox();
                    overflow: auto hidden;
                    padding-bottom: 1.3rem;
                }

                li {
                    margin-bottom: 0.6rem;

                    @include bp('lg-max') {
                        margin-right: 0.6rem;
                        margin-bottom: 0;
                        white-space: nowrap;

                        &:last-child {
                            margin-right: 0;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    a {
                        height: 100%;
                        padding: 1rem 1.2rem 1rem 2.8rem;
                        @include border-radius(0.6rem);
                        position: relative;
                        display: block;
                        text-transform: capitalize;
                        font-size: 1.4rem;
                        line-height: 2.4rem;
                        color: $grey;

                        @include bp('lg-max') {
                            padding: 1rem;
                            white-space: nowrap;
                        }

                        &:before {
                            content: '';
                            position: absolute;
                            width: 0.8rem;
                            height: 0.8rem;
                            left: 1.2rem;
                            top: 1.8rem;
                            background-color: $silver;
                            @include transition(all 0.5s);
                            @include border-radius(2.5rem);

                            @include bp('lg-max') {
                                width: 0;
                                height: 0.3rem;
                                @include border-radius(0.5rem);
                                background-color: $bluePrimary;
                                top: auto;
                                bottom: -0.6rem;
                                left: 0;
                                right: 0;
                            }
                        }

                        &:hover {
                            color: $bluePrimary;

                            &:before {
                                background-color: $bluePrimary;
                            }
                        }

                        &.active {
                            background-color: $bluePrimary;
                            color: $white;
                            font-weight: 500;

                            @include bp('lg-max') {
                                background-color: transparent;
                                color: $bluePrimary;
                            }

                            &:before {
                                background-color: $white;

                                @include bp('lg-max') {
                                    background-color: $bluePrimary;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .admin-result {
        width: calc(100% - 32.9rem);
        padding: 0 1.2rem;

        @include bp('xlg-max') {
            width: calc(100% - 30rem);
        }

        @include bp('lg-max') {
            width: 100%;
            padding: 0;
        }

        .admin-card {
            background-color: $white;
            padding: 2.6rem 2rem;
            box-shadow: 0 0 5rem 2rem rgba(65, 130, 226, 0.08);
            @include border-radius(1rem);
            height: 100%;
            min-height: calc(100vh - 49.1rem);

            @include bp('xlg-max') {
                padding: 2.6rem 1.5rem;
            }

            @include bp('lg-max') {
                box-shadow: none;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                padding: 1.5rem;
                min-height: inherit;
            }
        }
    }

    &.single-box {
        margin: 0;

        .admin-result {
            &.result-box-full {
                padding: 0;
                width: 100%;
            }
        }
        .page-footer-block {
            margin: 2.4rem 0 0;
            width: 100%;
        }
    }
}

// Common form lay out css
.field-with-label {
    @include flexbox();
    @include align-items(center);
    margin-bottom: 2rem;

    @include bp('sm-max') {
        @include flex-wrap(wrap);
        margin-bottom: 1.5rem;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .form-label {
        min-width: 8.8rem;
        margin-right: 1rem;
        text-align: right;

        @include bp('sm-max') {
            text-align: left;
            min-width: auto;
            margin-right: 0;
            margin-bottom: 0.4rem;
        }
    }

    .field-box {
        width: calc(100% - 9.8rem);

        @include bp('sm-max') {
            width: 100%;
        }
    }

    .MuiStack-root {
        padding-top: 0;
        overflow: hidden;

        > .MuiTextField-root {
            min-width: 100%;
            width: auto;

            .MuiOutlinedInput-input {
                @include placeholder {
                    text-transform: lowercase !important;
                }
            }
        }
    }
}

.field-with-button {
    @include flexbox();
    @include align-items(flex-start);

    @include bp('xs-max') {
        @include flex-wrap(wrap);
    }

    .field-with-label {
        margin-bottom: 0;
        width: calc(100% - 11rem);
        margin-right: 2rem;

        @include bp('xs-max') {
            margin-right: 0;
            margin-bottom: 2rem;
            width: 100%;
        }
    }

    .upload-btn {
        position: relative;
        padding: 1rem 1.25rem;
        min-width: 9rem;

        @include bp('sm-max') {
            font-size: 1.4rem;
            padding: 1.2rem 1.25rem;
        }

        @include bp('xs-max') {
            width: 100%;
        }

        .file-upload {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
}
