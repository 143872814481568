.create-role {
    .page-footer-block {
        @include bp('xs-max') {
            @include flex-wrap(wrap);
            @include align-items(flex-start);
        }

        .MuiButton-containedPrimary {
            @include bp('xs-max') {
                width: 50%;
                margin-bottom: 1rem;
            }

            @include bp('xxxs-max') {
                width: 100%;
                margin-bottom: 0;
            }
        }

        .MuiButton-outlinedPrimary {
            @include bp('xxxs-max') {
                margin-top: 1rem;
            }
        }
    }
}

.role-permission-outer {
    @include flexbox();
    @include flex-wrap(wrap);
    margin: 0 -1.2rem;

    @include bp('sm-max') {
        margin: 0;
    }

    &.user-permission {
        .permission-left {
            width: 26.7rem;

            @include bp('sm-max') {
                width: 100%;
            }
        }

        .permission-right {
            width: calc(100% - 26.7rem);

            @include bp('sm-max') {
                width: 100%;
            }
        }
    }

    .permission-left {
        width: 22.4rem;
        padding: 0 1.2rem;

        @include bp('sm-max') {
            width: 100%;
            padding: 0;
        }

        ul {
            margin-top: 1.1rem;

            @include bp('sm-max') {
                display: none;
            }

            li {
                margin-bottom: 0.6rem;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    position: relative;
                    padding: 1rem 1.2rem 1rem 2.8rem;
                    color: $grey;
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                    @include border-radius(0.6rem);
                    display: block;

                    &:after {
                        content: '';
                        position: absolute;
                        width: 0.8rem;
                        height: 0.8rem;
                        left: 1.2rem;
                        top: 1.8rem;
                        background-color: $grey;
                        @include transition(all 0.5s);
                        @include border-radius(2.5rem);
                    }

                    &.role-link-active {
                        background-color: rgba(65, 130, 226, 0.16);
                        color: $bluePrimary;

                        &:after {
                            background-color: $bluePrimary;
                        }
                    }

                    &:hover {
                        color: $bluePrimary;

                        &:after {
                            background-color: $bluePrimary;
                        }
                    }
                }
            }
        }

        .role-select-box {
            display: none;
            margin-top: 1.1rem;

            @include bp('sm-max') {
                display: block;
            }
        }
    }

    .permission-right {
        width: calc(100% - 22.4rem);
        padding: 0 1.2rem;

        @include bp('sm-max') {
            width: 100%;
            padding: 1.5rem 0 0;
        }
    }
}

.user-info-box {
    padding: 9rem 0;

    @include bp('lg-max') {
        padding: 5rem 0;
    }

    @include bp('sm-max') {
        padding: 3rem 0;
    }

    @include bp('xs-max') {
        padding: 1.5rem 0;
    }

    .user-info-view {
        border: 0.1rem solid $greyBorder;
        width: 51.6rem;
        max-width: 100%;
        padding: 4rem;
        @include border-radius(1.2rem);
        margin: 0 auto;

        @include bp('sm-max') {
            padding: 2rem 1.5rem;
            width: 100%;
            margin: 0;
        }

        li {
            margin-bottom: 2rem;
            @include flexbox();
            @include flex-wrap(wrap);

            @include bp('sm-max') {
                margin-bottom: 1rem;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .label {
                line-height: 2.4rem;
                text-transform: capitalize;
                width: 16.8rem;
                padding-right: 1rem;
                max-width: 100%;
                display: block;
                word-break: break-word;

                @include bp('xs-max') {
                    width: 100%;
                    padding-right: 0;
                    padding-bottom: 0.5rem;
                    line-height: 2rem;
                }
            }

            .label-info {
                font-weight: 500;
                color: $black2;
                line-height: 2.4rem;
                width: calc(100% - 16.8rem);
                max-width: 100%;
                word-break: break-word;

                @include bp('xs-max') {
                    width: 100%;
                    line-height: 2rem;
                }
            }
        }
    }

    &.material-list {
        padding: 3rem 0;

        @include bp('xs-max') {
            padding: 1.5rem 0;
        }

        .user-info-view {
            margin: 0;
            border: 0;
            padding: 0;
            width: auto;
            @include border-radius(0);

            li {
                .label {
                    width: 22rem;

                    @include bp('xs-max') {
                        width: 100%;
                    }
                }

                .label-info {
                    width: calc(100% - 22rem);

                    @include bp('xs-max') {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.add-user-from {
    padding: 2.9rem 0 2.4rem 4rem;
    width: 48.8rem;
    max-width: 100%;

    @include bp('sm-max') {
        padding: 1.5rem 0;
        margin: 0 auto;
        width: 100%;
    }

    .field-with-label {
        .form-label {
            min-width: 10.2rem;

            @include bp('sm-max') {
                min-width: auto;
            }
        }

        .field-box {
            width: calc(100% - 11.2rem);

            @include bp('sm-max') {
                width: 100%;
            }

            .MuiFormControl-root {
                width: 100%;
            }
        }
    }

    &.material-form {
        padding: 3rem 0;
        width: 42.2rem;

        @include bp('sm-max') {
            padding: 1.5rem 0;
            margin: 0;
            width: 100%;
        }

        .field-with-label {
            .form-label {
                min-width: 11.8rem;

                @include bp('sm-max') {
                    min-width: auto;
                }
            }

            .field-box {
                width: calc(100% - 12.8rem);

                @include bp('sm-max') {
                    width: 100%;
                }
            }
        }
    }
}
