.admin-main-box {
    &.single-box {
        .admin-result {
            &.result-box-full {
                .page-info-block {
                    .page-info-left {
                        .topic-box {
                            width: 42.2rem;

                            @include bp('lg-max') {
                                width: 30rem;
                            }

                            @include bp('smd-max') {
                                width: 25rem;
                            }

                            @include bp('sm-max') {
                                width: 100%;
                            }

                            .form-label {
                                min-width: 10.4rem;

                                @include bp('sm-max') {
                                    min-width: auto;
                                }
                            }

                            .MuiTextField-root {
                                width: 100%;

                                @include bp('lg-max') {
                                    width: 100%;
                                }
                            }
                        }

                        .date-box-outer {
                            @include flexbox();
                            @include align-items(center);
                            width: calc(100% - 46.2rem);
                            max-width: 100%;
                            margin-left: 2rem;

                            @include bp('lg-max') {
                                width: calc(100% - 31.5rem);
                                margin-left: 1.5rem;
                            }

                            @include bp('smd-max') {
                                width: calc(100% - 27rem);
                                margin-left: 1.5rem;
                            }

                            @include bp('sm-max') {
                                width: 100%;
                                margin-left: 0;
                            }

                            .form-label {
                                white-space: nowrap;
                                &.secondary-label {
                                    min-width: 8.8rem;

                                    @include bp('sm-max') {
                                        min-width: auto;
                                    }
                                }
                            }

                            .date-box {
                                .MuiStack-root {
                                    width: 100%;
                                    max-width: 100%;
                                    overflow: hidden;

                                    > .MuiTextField-root {
                                        min-width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
