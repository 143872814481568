$black: #000000;
$black2: #262626;
$white: #ffffff;
$textPrimary: #454545;
$bluePrimary: #4182E2;
$bluePrimaryDark: #004cbe;
$skyPrimary: #9EBAE0;
$skyPrimaryLight: #E0EDFF;
$grey: #757575;
$greyBorder: #D7D7D7;
$silver: #B5B5B5;
$red: #F34E4E;