.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 5;
	background-color: $skyPrimaryLight;

	&_top {
		background-color: $bluePrimary;
		padding: 0.9rem 0;

		.MuiContainer-root {
			@include flexbox();
			@include justify-content(space-between);
			@include align-items(center);
		}

		&_left {
			@include flexbox();
			@include align-items(center);

			li {
				margin-right: 3.4rem;

				@include bp('xs-max') {
					margin-right: 1rem;
				}

				&:last-child {
					margin-right: 0;
				}

				a {
					line-height: 2.4rem;
					font-weight: 500;
					color: $white;
					@include flexbox();
					@include align-items(center);

					@include bp('xs-max') {
						font-size: 0;
					}

					.header-icon {
						min-width: 2.4rem;
						margin-right: 0.8rem;
						line-height: 0;

						@include bp('xs-max') {
							margin-right: 0;
							min-width: 2rem;
						}

						img {
							@include bp('xs-max') {
								max-width: 2rem;
							}
						}
					}

					&:hover {
						opacity: 0.6;
					}
				}
			}
		}

		&_right {
			@include flexbox();
			@include align-items(center);
			margin-right: -0.4rem;

			@include bp('xs-max') {
				margin-right: 0;
			}

			li {
				margin-right: 0.6rem;

				&:last-child {
					margin-right: 0;
				}

				a {
					button {
						img {
							@include bp('xs-max') {
								max-width: 1.6rem;
							}
						}
					}
				}
			}
		}
	}

	&_bottom {
		border-bottom: 0.1rem solid $skyPrimary;
		padding: 1.2rem 0 1rem 0;

		@include bp('lg-max') {
			padding: 1rem 0;
		}

		@include bp('md-max') {
			background-color: $skyPrimaryLight;
		}


		.MuiContainer-root {
			@include flexbox();
			@include justify-content(space-between);
		}

		.user-info {
			@include flexbox();

			@include bp('md-max') {
				@include align-items(center);
			}

			.hamburger-menu {
				width: 2.8rem;
				height: 2.2rem;
				position: relative;
				cursor: pointer;
				margin-right: 1.5rem;
				display: none;

				@include bp('md-max') {
					display: block;
				}

				span {
					position: absolute;
					display: block;
					width: 100%;
					height: 0.3rem;
					@include border-radius(0.4rem);
					@include transition(all 0.5s);
					background-color: $bluePrimary;
					left: 0;
					top: 0;

					&:nth-child(2) {
						top: 0.8rem;
					}

					&:last-child {
						top: 1.7rem;
					}
				}
			}

			.logo {
				line-height: 0;

				@include bp('lg-max') {
					max-width: 10rem;
				}

				>img {
					display: block;
				}
			}
		}

		nav {
			width: 95.8rem;
			max-width: 100%;
			@include transition(all 0.5s);

			@include bp('lg-max') {
				width: 80rem;
			}

			@include bp('md-max') {
				width: 32rem;
				left: -100%;
				position: fixed;
				top: 13.8rem;
				bottom: 0;
				z-index: 4;
				background-color: $skyPrimaryLight;
				padding: 1.5rem;
				overflow-y: auto;
			}

			@include bp('xs-max') {
				top: 13.4rem;
			}

			ul {
				@include flexbox();
				@include flex-wrap(wrap);
				width: 56rem;
				max-width: 100%;
				margin-bottom: -1.2rem;

				@include bp('lg-max') {
					margin-bottom: -1rem;
				}

				@include bp('md-max') {
					width: 100%;
					margin-bottom: 0;
					display: block;
				}

				li {
					margin: 0 2.4rem 1.2rem 0;

					@include bp('lg-max') {
						margin: 0 1.2rem 1rem 0;
					}

					@include bp('md-max') {
						margin: 0 0 1rem 0;
					}

					&:last-child {
						margin-right: 0;
					}

					a {
						border: 0.1rem solid $textPrimary;
						@include border-radius(0.6rem);
						padding: 0.8rem 0.9rem;
						font-size: 1.4rem;
						line-height: 2.2rem;
						font-weight: 500;
						color: $textPrimary;

						@include bp('md-max') {
							border: 0;
							@include border-radius(0);
							text-align: center;
							display: block;
						}

						&.active {
							background-color: rgba($white, 0.7);
							border-color: $bluePrimary;
							color: $bluePrimary;

							@include bp('md-max') {
								background-color: transparent;
							}
						}

						&:hover:not(.active) {
							color: $bluePrimary;
							border-color: $bluePrimary;
							background-color: rgba($white, 0.7);

							@include bp('md-max') {
								background-color: transparent;
								border-color: transparent;
							}
						}
					}
				}
			}

			.button-block {
				text-align: right;

				@include bp('md-max') {
					text-align: left;
				}

				.MuiButton-outlined {
					margin-right: 1.4rem;
					padding: 0.5rem 1.2rem;
					font-size: 1.4rem;

					@include bp('md-max') {
						margin-right: 0;
						margin-bottom: 1rem;
						width: 100%;
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}

	&.admin-header {
		.header_bottom {
			padding: 1.8rem 0;

			.MuiContainer-root {
				@include align-items(center);
			}

			nav {
				width: 100%;

				@include bp('md-max') {
					width: 32rem;
				}

				ul {
					@include justify-content(flex-end);
					width: 100%;
					margin: 0;

					li {
						margin: 0 2.4rem 0 0;

						&:last-child {
							margin: 0;
						}
					}
				}
			}
		}
	}
}