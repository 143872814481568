.table-outer {

    margin-top: 1.6rem;

    .MuiTableContainer-root {
        @include border-radius(0.6rem);
        border: 0.1rem solid $greyBorder;

        table {
            thead {
                tr {
                    th {
                        background-color: rgba(65, 130, 226, 0.14);

                        .MuiFormControlLabel-root {
                            margin: 0;
                            min-width: 1.6rem;

                            .MuiCheckbox-root {
                                padding: 0;
                            }
                        }

                        .has-sorting {
                            @include flexbox();
                            @include align-items(center);
                            cursor: pointer;
                            @include transition(all 0.5s);

                            .sorting {
                                margin-left: 0.9rem;
                                line-height: 0;
                                min-width: 1.6rem;
                            }

                            &:hover {
                                opacity: 0.6;
                            }
                        }
                    }
                }
            }

            tbody {
                tr {
                    &:nth-child(odd) {
                        background-color: $white;
                    }

                    &:nth-child(even) {
                        background-color: rgba(65, 130, 226, 0.14);
                    }

                    td {
                        font-weight: 400;

                        &:last-child {
                            text-align: left;
                        }

                        .custom-radio {
                            @include align-items(center);
                        }

                        .custom-radio-hidden {
                            opacity: 0;
                            pointer-events: none;
                        }

                        .MuiFormControlLabel-root {
                            margin: 0;
                            min-width: 1.6rem;

                            .MuiCheckbox-root {
                                padding: 0;
                            }

                            .MuiRadio-root {
                                padding: 0;
                            }
                        }

                        &.text-center {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }


    .pagination-block {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        margin-top: 1.6rem;

        @include bp('xs-max') {
            @include justify-content(center);
            @include flex-wrap(wrap);
        }

        &_left {
            @include flexbox();
            @include align-items(center);

            @include bp('xs-max') {
                width: 100%;
                @include justify-content(center);
                margin-bottom: 1rem;
            }

            .pagination-select {
                margin-right: 1.2rem;

                .MuiFormControl-root {
                    min-width: 5.4rem;

                    .MuiOutlinedInput-root {
                        padding-right: 0.7rem;

                        .MuiSelect-select {
                            min-height: auto;
                            font-size: 1.4rem;
                            padding: 1.3rem 1rem;
                        }

                        .MuiSelect-icon {
                            right: 0.6rem;
                        }
                    }
                }
            }

            p {
                font-size: 1.4rem;
                line-height: 1.8rem;
                color: $black2;
            }
        }

        &_right {
            .MuiPagination-ul {
                li {
                    &:last-child {
                        .MuiPaginationItem-text {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}