.home-page {
    position: relative;
    padding: 8rem 3rem 0;

    @include bp('md-max') {
        padding: 3rem 3rem 0;
    }

    @include bp('smd-max') {
        padding: 3rem 0 0;
    }

    &:after,
    &:before {
        content: '';
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        height: 45.3rem;

        @include bp('lg-max') {
            display: none;
        }
    }

    &:after {
        background-image: url('../../images/design-right.svg');
        top: -23rem;
        right: 1rem;
        width: 23.7rem;
    }

    &:before {
        background-image: url('../../images/design-left.svg');
        bottom: -4.4rem;
        left: 0rem;
        width: 23rem;
    }

    .home-section {
        // height: calc(100vh - 34.7rem);
        // position: relative;
        // min-height: 51.7rem;

        // @include bp('md-max') {
        //     min-height: 61rem;
        // }

        // @include bp('smd-max') {
        //     height: auto;
        //     min-height: inherit;
        // }
    }

    // About US Page CSS
    &.about-page {
        padding: 8rem 3rem 3rem;

        @include bp('smd-max') {
            padding: 5rem 0 3rem;
        }

        @include bp('sm-max') {
            padding: 3rem 0;
        }

        &:after,
        &:before {
            display: none;
        }

        .home-section {
            padding: 0 11rem;
            height: auto;
            min-height: inherit;

            @include bp('xlg-max') {
                padding: 0 5rem;
            }

            @include bp('lg-max') {
                padding: 0;
            }

            .page-outer {
                @include bp('smd-max') {
                    margin: 0 -1.5rem;
                }

                @include bp('sm-max') {
                    margin: 0;
                }

                .page-outer_left {
                    @include bp('smd-max') {
                        width: 50%;
                        padding: 0 1.5rem;
                    }

                    @include bp('sm-max') {
                        width: 100%;
                        padding: 0 0 5rem;
                    }

                    .page-content {
                        @include bp('smd-max') {
                            width: auto;
                            text-align: left;
                        }

                        @include bp('sm-max') {
                            text-align: center;
                        }

                        .MuiTypography-h2 {
                            margin-bottom: 2rem;
                        }
                    }
                }

                .page-outer_right {
                    @include bp('smd-max') {
                        width: 50%;
                        padding: 0 1.5rem;
                    }

                    @include bp('sm-max') {
                        width: 100%;
                        padding: 0;
                    }
                }
            }
        }
    }

    // Contact us Page CSS
    &.contact-page {
        .page-content {
            margin-top: -1rem;

            @include bp('sm-max') {
                margin-top: 0;
            }
        }
    }

    // Career Page CSS Start
    &.career-page {
        .home-section {
            padding: 0 9rem;

            @include bp('xlg-max') {
                padding: 0 5rem;
            }

            @include bp('lg-max') {
                padding: 0;
            }

            .page-outer {
                @include bp('md-max') {
                    margin: 0 -1.5rem;
                }

                @include bp('smd-max') {
                    margin: 0;
                }

                .page-outer_left {
                    @include bp('md-max') {
                        width: 60%;
                        padding: 0 1.5rem;
                    }

                    @include bp('smd-max') {
                        width: 100%;
                        padding: 0 0 5rem;
                    }

                    .page-content {
                        margin-top: -0.7rem;
                        text-align: left;

                        @include bp('smd-max') {
                            margin-top: 0;
                        }

                        .career-note {
                            font-size: 1.8rem;

                            @include bp('sm-max') {
                                font-size: 1.6rem;
                            }
                        }
                    }
                }

                .page-outer_right {
                    @include bp('md-max') {
                        width: 40%;
                        padding: 0 1.5rem;
                    }

                    @include bp('smd-max') {
                        width: 100%;
                        padding: 0;
                    }

                    .right-bg {
                        margin-top: -2rem;

                        @include bp('smd-max') {
                            margin-top: 2.2rem;
                            max-width: 42rem;
                        }

                        @include bp('sm-max') {
                            max-width: 30rem;
                        }

                        &:before {
                            @include bp('smd-max') {
                                right: 0;
                            }

                            @include bp('sm-max') {
                                right: -1rem;
                            }
                        }

                        img {
                            @include bp('smd-max') {
                                max-width: 40rem;
                            }

                            @include bp('sm-max') {
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
